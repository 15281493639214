<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <div class="info-box">
        <div class="head-card">
          <div class="head-card-left">
            <img class="shadow" src="@/assets/img/wang/shadow.png" />
          </div>
          <div class="head-card-right">
            <div class="title-box">
              <div class="title-box-left" v-if="myinfo.warningFlag">
                <div>已预警</div>
              </div>
              <div class="title-box-right">{{ myinfo.title || " 无" }}</div>
            </div>
            <div class="early-warning">
              <img
                class="header-img"
                v-if="myinfo.artificialMessageTypesName"
                src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202004%2F14%2F20200414210224_dnzpo.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672910975&t=4f572bc1a2f09b5a21a599062a8883e0"
              />
              <el-popover
                placement="right-start"
                popper-class="monitor-yt-popover"
                effect="dark"
                width="400"
                trigger="hover"
              >
                <div class="popover">
                  <div
                    class="popover-type m-l-16 m-t-16"
                    v-for="(value, inx) in xinxiTypeList"
                    :key="inx"
                  >
                    <el-tooltip class="item" effect="dark" placement="top-start">
                      <div slot="content">{{ value }}</div>
                      <div class="texthidden">{{ value }}</div>
                    </el-tooltip>
                  </div>
                </div>
                <div slot="reference">
                  <div class="type" v-if="xinxiTypeList.length > 0">
                    {{ xinxiTypeList[0] }}
                  </div>
                </div>
              </el-popover>
              <div class="platform-type">{{ myinfo.platformName }}</div>
              <div class="time">发布时间：{{ myinfo.publishTime }}</div>
            </div>
            <div class="operation">
              <div class="copy" @click="$main.copyFun(myinfo.copyText)">
                <img src="@/assets/img/wang/copy-all.png" />
                <span>复制内容</span>
              </div>
              <div
                class="copy"
                @click="$main.copyFun('标题：' + myinfo.title + '\n' + '链接：' + myinfo.sourceUrl)"
              >
                <img src="@/assets/img/wang/copy-link.png" />
                <span>复制链接和标题</span>
              </div>
              <div
                class="copy"
                @click="operation({ type: 1, item: myinfo })"
                v-if="!myinfo.collectFlag"
              >
                <img src="@/assets/img/wang/collection.png" />
                <span>加入收藏</span>
              </div>
              <div class="copy" v-else @click="operation({ type: 6, item: myinfo })">
                <img src="@/assets/img/zhang/yishoucang.png" />
                <span style="color:#f58030">已收藏</span>
              </div>
            </div>
          </div>
          <img class="shadow2" src="@/assets/img/wang/shadow2.png" />
          <img
            v-if="myinfo.disposalFlag"
            class="disposed"
            src="@/assets/img/wang/disposed.png"
            alt=""
          />
          <img v-else class="disposed" src="@/assets/img/wang/undisposed.png" alt="" />
        </div>
        <div class="platform">
          <div class="platform-name">
            <span>平台类型：{{ myinfo.platformName }}</span>
            <img @click="$main.copyFun(myinfo.platformName)" src="@/assets/img/wang/copy-all.png" />
          </div>
          <div class="platform-name">
            <span>账号名称/网站名称：{{ myinfo.accountName || "无" }}</span>
            <img @click="$main.copyFun(myinfo.accountName)" src="@/assets/img/wang/copy-all.png" />
          </div>
          <div class="platform-name">
            <span>
              问题类型：{{
                myinfo.artificialMessageTypesName || myinfo.machineMessageTypesName || "无"
              }}
            </span>
            <img
              @click="
                $main.copyFun(myinfo.artificialMessageTypesName + myinfo.machineMessageTypesName)
              "
              src="@/assets/img/wang/copy-all.png"
            />
          </div>
          <img class="img" src="@/assets/img/wang/shadow2.png" />
        </div>
        <div class="content">{{ myinfo.content || "无" }}</div>
      </div>
      <!-- 相似信息 -->
      <div class="be-similar">
        <div class="be-similar-account">
          <span>相似信息</span>
          <span>（{{ likenum }}条）</span>
          <div class="be-similar-color"></div>
        </div>
        <div class="data-box" v-for="(item, index) in likeList" :key="index">
          <div class="data-box-right">
            <div class="name-box">
              <div class="name-box-left">
                <span>涉嫌赌博</span>
                <span>涉嫌恐怖</span>
                <div class="name">
                  {{ item.title || "无" }}
                </div>
              </div>
              <div class="time">{{ item.publishTime || "无" }}</div>
            </div>
            <div class="data-info">
              <div class="subject-type">平台类型：微博</div>
              <div class="region">所属区域：{{ item.source || "无" }}</div>
              <div class="subject-type">账号名称：{{ item.accountName || "无" }}</div>
              <div class="company">作者名称：{{ item.author || "无" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <deleteDialog ref="openDialog" @deleteFn="operation"></deleteDialog>
  </div>
</template>

<script>
import deleteDialog from "@/components/deleteDialog.vue";
import { update } from "@/api/harmful/index";
import Breadcrumb from "@/components/breadcrumb.vue";
import { detail, similarTitle } from "@/api/harmful/index";
export default {
  components: { Breadcrumb, deleteDialog },
  data() {
    return {
      xinxiTypeList: [],
      routerList: ["系统设置", "详情"],
      dataId: null,
      myinfo: {}, // 详情信息
      likeList: [], // 相似列表
      likenum: 0, // 相似列表总数
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.id;
    }
    this.getdetail();
  },
  methods: {
    back() {
      this.$router.push("/settings");
    },
    toDetail(inx) {
      this.$router.push(
        {
          1: { path: "/harmfulDetail" },
        }[inx]
      );
    },
    //  详情
    async getdetail() {
      const { dataId } = this;
      const Res = await detail(dataId);
      const { data } = this.$main.getResData(Res);
      this.myinfo = data;
      let { artificialMessageTypesName, machineMessageTypesName } = data;
      this.similarTitle(data);
      if (artificialMessageTypesName.length > 0) {
        this.xinxiTypeList = artificialMessageTypesName.split(",");
      } else if (machineMessageTypesName.length > 0) {
        this.xinxiTypeList = machineMessageTypesName.split(",");
      } else {
        this.xinxiTypeList = [];
      }
    },
    //  调用相似接口
    async similarTitle(obj) {
      const { similarDataNum, accountId, titleHash, id } = obj;
      const Res = await similarTitle([
        {
          returnSimilarData: true,
          similarDataNum,
          accountId,
          titleHash,
          id,
        },
      ]);
      const { data } = this.$main.getResData(Res);
      this.likenum = Number(data[0].num);
      this.likeList = data[0].similarData;
    },
    deleteFnMy(item) {
      this.$refs.openDialog.openDialog(item);
    },
    async operation({ type, item }) {
      // 操作类别: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6撤销收藏
      // 操作类别-type: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6 取消收藏
      const { id } = item;
      const Res = await update({
        dataIds: id,
        operationType: type,
      });
      const { code } = Res.data;
      if (code == 200) {
        let typeText = "";
        if (type == 1) {
          typeText = "已收藏";
          item.collectFlag = true;
        } else if (type == 6) {
          typeText = "取消收藏";
          item.collectFlag = false;
        } else if (type == 2) {
          typeText = "成功删除";
          this.$router.push("/harmful");
        }
        this.$message({
          message: `${typeText ? typeText : "操作成功"}`,
          type: "success",
        });
      } else {
        this.$message.error("操作失败!");
      }
    },
  },
};
</script>
<style>
/* //最外层div,修改背景色，边框 */
.el-popover.monitor-yt-popover {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 0 16px 0;
  /* border-color: #146ebd; */
}
/* //修改title颜色 */
.monitor-yt-popover .el-popover__title {
  color: white;
}
/* //修改下面的小三角，属性名根据组件的placement位置做相应修改 */
.monitor-yt-popover .popper__arrow::after {
  border-right-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
<style lang="scss" scoped>
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;
        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;
          .shadow {
            height: 74px;
            width: 57px;
          }
        }
        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }
        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;
          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }
          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
        .early-warning {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin: 16px 0px;
          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }
          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }
          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: auto;
          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .shadow2 {
          height: 100%;
          width: 170px;
        }
        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }
      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px;
        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          margin-left: 1000px;
          margin-bottom: 24px;
        }
        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }
      .content {
        width: 1164px;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-indent: 25px;
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        cursor: pointer;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;
            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }
              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}

.popover {
  // width: 300px;
  // height: 300px;
  // background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // justify-content:space-evenly
  .popover-type {
    cursor: pointer;
    width: 114px;
    min-height: 28px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: 4px 10px;
    background: #ff4b1f;
    border-radius: 1px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.type {
  cursor: pointer;
  width: 114px;
  min-height: 28px;
  text-align: center;
  padding: 4px 10px;
  background: #ff4b1f;
  border-radius: 1px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 15px;
}
</style>
